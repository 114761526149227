<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">
				{{ name }} - Comtech E<sub>b</sub>/N<sub>0</sub>
			</h3>
		</div>
		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<div v-if="error" class="pt-5">
					<b-alert show variant="warning" class="m-0">
						<span class="svg-icon-danger">
							<inline-svg src="/media/svg/icons/Code/Warning-2.svg"></inline-svg>
						</span>
						{{ errorMsg }}
					</b-alert>
				</div>
				<dygraphs v-else-if="dyData.length > 0" :data="dyData" :options="dyOpts" />
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
const date = new Date();
const offset = date.getTimezoneOffset() * 60000;
export default {
	props: ['name', 'prom'],
	name: 'SolarwindsComtechEbN0',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
	},
	data() {
		return {
			loaded: false,
			dyData: [],
			dyOpts: {},
			data: {},
			error: false,
			errorMsg: 'There was an error retrieving data for this device',
		};
	},
	methods: {
		processData() {
			if (!this.data.data?.data?.data || this.data.data.data.data.length == 0) {
				throw { data: data, e: 'no data' };
			}
			var labels = ['Timestamp'];
			var graphdata = [];
			var ebno = true; // used in case older model that calls it esno
			var esnolabel = [];
			var remoteebno = false;
			var dddd = this.data.data.data.data;
			if (this.data.data.data.types?.remoteebno) {
				remoteebno = true;
			}

			Object.keys(dddd).forEach(ts => {
				var d = [];
				if (remoteebno) {
					if (dddd[ts].ebno) {
						d.push(dddd[ts].ebno);
					} else {
						d.push(null);
					}
					if (dddd[ts].remoteebno) {
						d.push(dddd[ts].remoteebno);
					} else {
						d.push(null);
					}
					d.unshift(new Date(ts * 1000 + offset));
					graphdata.push(d);
				} else {
					if (dddd[ts].ebno) {
						d.push(dddd[ts].ebno);
					} else {
						d.push(null);
					}
					d.unshift(new Date(ts * 1000 + offset));
					graphdata.push(d);
				}

				if (dddd[ts].esno) {
					var dt = [new Date(ts * 1000 + offset)];
					dddd[ts].esno.forEach((k, p) => {
						if (ebno) {
							esnolabel.push('Es/No ' + p);
						}
						dt.push(k);
					});
					graphdata.push(dt);
					ebno = false;
				}
			});
			if (ebno) {
				labels.push('Eb/N0');
				if (remoteebno) {
					labels.push('Remote Eb/N0');
				}
			} else {
				// esn0 labels
				labels = labels.concat(esnolabel);
			}
			this.dyOpts = {
				axes: {
					y: {
						valueFormatter: function(y) {
							return y + ' dB';
						},
					},
				},
				labels: labels,
				includeZero: true,
				labelsKMG2: false,
				connectSeparatedPoints: true,
				labelsSeparateLines: false,
				fillGraph: labels.length == 2 ? true : false,
				strokeWidth: 1.5,
				fillAlpha: 0.06,
			};

			this.dyData = graphdata;
		},
		load() {
			this.prom
				.then(d => {
					this.data = d;
					this.processData();
					this.loaded = true;
				})
				.catch(e => {
					if (e.data && e.data == 'No data for selected range') {
						this.error = true;
						this.errorMsg = e.data;
					} else {
						this.errorMsg = 'There was an error retrieving data for this device.  ' + e;
						this.error = true;
					}
					this.loaded = true;
				});
		},
	},
	watch: {
		prom() {
			this.load();
		},
	},
	created() {
		this.load();
	},
};
</script>

<style></style>
